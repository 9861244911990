<template>
  <div v-for="(num, id) in 3" :key="id" class="row mt-4">
    <!-- For mobile viewport, show native date, time picker -->
    <label
      class="form-label"
      :class="{ required: id === 0 }"
      :for="`dateRequestDate${id + 1}`"
      >{{
        $t(
          `product.subscribeToEventUpdatesModal.firstStep.form.date${
            id + 1
          }.label`
        )
      }}
    </label>
    <div class="mt-1 relative w-full sm:hidden">
      <div class="relative w-full">
        <input
          :id="`dateRequestDate${id + 1}`"
          v-model="form.dates[id]"
          :readonly="isMobile"
          :min="minDateFormatted"
          step="3600"
          :placeholder="
            $t(
              `product.subscribeToEventUpdatesModal.firstStep.form.date${
                id + 1
              }.placeholder`
            )
          "
          type="datetime-local"
          :autocomplete="`dateRequestDate${id + 1}`"
          class="w-full border border-gray-200 rounded-md p-2 bg-white mt-1"
          :data-test-id="`date-request-date-picker-${id + 1}`"
          :name="`dateRequestDate${id + 1}`"
        />

        <!-- <i
              class="bi bi-calendar-date-fill position-absolute top-50 start-0 ms-3 fs-4 translate-middle-y text-black-50"
            ></i> -->
      </div>
    </div>
    <div class="mt-1 relative w-full hidden sm:block">
      <Datepicker
        v-model="form.dates[id]"
        model-type="yyyy-MM-dd'T'HH:mm"
        time-picker-inline
        :format-locale="ja"
        format="dd.MM.yyyy, HH:mm"
        locale="de"
        select-text="auswählen"
        text-input
        minutes-increment="30"
        :start-time="startTime"
        :disabled-dates="isDateDisabled"
        :disabled-week-days="getDisabledWeekDays()"
        :start-date="minDate"
        :placeholder="$t('product.show.checkout.selectDate')"
        @open="$emit('clearErrors', 'dates')"
      />
    </div>

    <span
      v-if="invalidKeys.includes('dates') && id === 0"
      data-test-id="date-request-form-invalid"
      class="text-red text-sm"
    >
      {{ errors.dates }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { de } from 'date-fns/locale'
import dayjs from 'dayjs'
interface Props {
  eventData: EventDescription
  errors: DateRequestErrors
  invalidKeys: DateRequestInvalidKeys
}
const props = withDefaults(defineProps<Props>(), {
  eventData: {},
  errors: {},
  invalidKeys: [],
})
const emit = defineEmits<{
  clearErrors: [id: string]
}>()
const form = defineModel()
const startTime = ref({ hours: 18, minutes: 0 })
const timeRange = [8, 21]
const hours = Array.from({
  length: timeRange[1] - timeRange[0],
}).map((_, i) => i + timeRange[0])

// uses the minAdvance setting to determine the first bookable
// date in ISO 8601 format
const minDate = dayjs().add(
  eventGetters.getDateRequestMinAdvanceQuantity(props.eventData),
  eventGetters.getDateRequestMinAdvanceUnit(props.eventData).toLowerCase()
)

const minDateFormatted = minDate.format('YYYY-MM-DD')

const getDisabledWeekDays = () =>
  [0, 1, 2, 3, 4, 5, 6].filter(
    (day) =>
      !eventGetters.getDateRequestAvailableDays(props.eventData).includes(day)
  )

const isDateDisabled = (date) => date < minDate
</script>
<style>
.required:after {
  content: '*';
  position: relative;
  font-size: inherit;
  @apply text-red;
  padding-left: 0.25rem;
  font-weight: 700;
}
.dp__menu {
  font-family: 'Lexend';
}
</style>
