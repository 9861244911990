import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "22",
  height: "22",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#E5E7EB",
      d: "M2.378 2.378a1.286 1.286 0 0 1 1.82 0L11 9.183l6.803-6.805a1.287 1.287 0 0 1 1.82 1.82L12.818 11l6.805 6.803a1.288 1.288 0 0 1-1.82 1.82L11 12.818l-6.802 6.805a1.288 1.288 0 0 1-1.82-1.82L9.183 11 2.378 4.198a1.286 1.286 0 0 1 0-1.82"
    }, null, -1)
  ])))
}
export default { render: render }