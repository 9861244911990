<template>
  <!-- For mobile viewport, show native date, time picker -->
  <label class="form-label" for="company-name">{{
    $t('product.subscribeToEventUpdatesModal.firstStep.form.companyName.label')
  }}</label>
  <div class="position-relative">
    <input
      id="company-name"
      v-model="form.companyName"
      autocomplete="company name"
      class="w-full border border-gray-200 rounded-md p-2 bg-white mt-1"
      name="company-name"
      @input="$emit('clearErrors', 'name')"
    />

    <!-- <i
      class="bi bi-person-fill position-absolute top-50 start-0 ms-3 fs-4 translate-middle-y text-black-50"
    ></i> -->
    <span class="block mb-2 text-xs text-gray-400">
      {{
        $t(
          'product.subscribeToEventUpdatesModal.firstStep.form.companyName.helperText'
        )
      }}
    </span>
  </div>
  <label class="form-label" for="privateRequestData"
    >{{ $t('product.show.checkout.selectDate') }}
  </label>
  <div class="mt-1 relative w-full sm:hidden">
    <div class="relative w-full">
      <input
        id="privateRequestData"
        v-model="form.dates[0]"
        :readonly="isMobile"
        :min="minDate"
        data-test-id="date-request-date-picker-1"
        step="3600"
        :placeholder="$t('product.show.checkout.selectDate')"
        type="datetime-local"
        class="w-full border border-gray-200 rounded-md p-2 bg-white mt-1"
        name="privateRequestData"
      />

      <!-- <i
              class="bi bi-calendar-date-fill position-absolute top-50 start-0 ms-3 fs-4 translate-middle-y text-black-50"
            ></i> -->
    </div>
  </div>
  <div class="mt-1 relative w-full hidden sm:block">
    <Datepicker
      v-model="form.dates[0]"
      model-type="yyyy-MM-dd'T'HH:mm"
      time-picker-inline
      :format-locale="ja"
      format="dd.MM.yyyy, HH:mm"
      locale="de"
      select-text="auswählen"
      text-input
      minutes-increment="30"
      :start-time="startTime"
      :placeholder="$t('product.show.checkout.selectDate')"
      @open="$emit('clearErrors', 'dates')"
    />
  </div>
  <span class="block mb-2 text-xs text-gray-400">
    {{
      $t('product.subscribeToEventUpdatesModal.firstStep.form.date1.helperText')
    }}
  </span>
</template>

<script lang="ts" setup>
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { de } from 'date-fns/locale'
import dayjs from 'dayjs'
interface Props {
  eventData: EventDescription
}
const props = withDefaults(defineProps<Props>(), {
  eventData: {},
})
const form = defineModel()
</script>
