<script lang="ts" setup>
import IconClose from '@/public/icons/icon-close.svg?component'

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  dataTestId: {
    type: String,
    default: '',
  },
})
</script>

<template>
  <!-- drawer component -->
  <div
    id="drawer"
    class="shadow-lg overflow-hidden fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform duration-300 bg-white w-80"
    :class="[
      {
        'translate-x-full': !props.isOpen,
        'translate-none': props.isOpen,
      },
    ]"
    tabindex="-1"
    aria-labelledby="drawer-right-label"
  >
    <div class="mb-4">
      <slot name="header"> </slot>
      <button
        type="button"
        aria-controls="drawer"
        aria-label="Close"
        :data-test-id="`${dataTestId}-close-button`"
        class="close-button"
        @click="$emit('click:close')"
      >
        <IconClose />
        <span class="sr-only">Close menu</span>
      </button>
    </div>

    <slot></slot>
  </div>
</template>

<style lang="postcss" scoped>
.close-button {
  @apply hover:rotate-90 duration-300 w-[22px] h-[22px];
  @apply absolute top-3.5 md:top-4 end-2.5 mt-1 inline-flex items-center justify-center;

  &:hover {
    svg path {
      fill: #000;
    }
  }
}
</style>
